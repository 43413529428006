import {
  faA,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAnglesRight,
  faAngleUp,
  faArrowDown,
  faArrowDownFromDottedLine,
  faArrowLeft,
  faArrowRight,
  faArrowsMinimize,
  faArrowsToLine,
  faArrowsUpDownLeftRight,
  faArrowUp,
  faAsterisk,
  faBallPile,
  faBars,
  faBarsFilter,
  faBasketShopping,
  faBell,
  faBellOn,
  faBookmark,
  faBorderOuter,
  faBorderTopLeft,
  faBox,
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faCalendarPlus,
  faCalendarRange,
  faCalendarWeek,
  faCamera,
  faCartShopping,
  faCheck,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronsRight,
  faChevronsUp,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCircleNotch,
  faCirclePlay,
  faCirclePlus,
  faCircleQuestion,
  faCircleSmall,
  faCircleUser,
  faCircleXmark,
  faClipboard,
  faClock,
  faClockRotateLeft,
  faClone,
  faCloudsMoon,
  faCopy,
  faDisplay,
  faDown,
  faDownload,
  faEllipsis,
  faEllipsisH,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpenText,
  faExclamation,
  faExclamationTriangle,
  faExpand,
  faEye,
  faEyeSlash,
  faFileArrowDown,
  faFileExcel,
  faFileInvoice,
  faFilePdf,
  faFilePlus,
  faFillDrip,
  faFilter,
  faFireFlameCurved,
  faFlag,
  faFolderArrowDown,
  faFolderOpen,
  faGear,
  faGhost,
  faGrid2,
  faGrid2Plus,
  faHeart,
  faImagePolaroid,
  faIndent,
  faIndustryWindows,
  faInfinity,
  faLayerGroup,
  faLeft,
  faLeftRight,
  faLightbulbDollar,
  faLink,
  faList,
  faListTree,
  faLocationDot,
  faLock,
  faMagnifyingGlass,
  faMagnifyingGlassDollar,
  faMedal,
  faMegaphone,
  faMemo,
  faMemoCircleCheck,
  faMessage,
  faMessageLines,
  faMinus,
  faMobileScreen,
  faMoneyCheckDollar,
  faObjectGroup,
  faPaperPlane,
  faPen,
  faPencil,
  faPenNib,
  faPhoneSlash,
  faPlus,
  faPrint,
  faRepeat,
  faRight,
  faRotateLeft,
  faShapes,
  faShareNodes,
  faShirt,
  faSpinner,
  faSquare,
  faSquareDollar,
  faSquarePlus,
  faSquareSlidersVertical,
  faStar,
  faStore,
  faTag,
  faText,
  faThumbTack,
  faTrash,
  faTrashCan,
  faTrashList,
  faTriangleExclamation,
  faTrophyStar,
  faTruck,
  faTruckFast,
  faUp,
  faUpDown,
  faUpload,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUsers,
  faVideo,
  faVideoArrowUpRight,
  faVolume,
  faVolumeSlash,
  faWandMagicSparkles,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';

export const fas = {
  [faBars.iconName]: faBars,
  [faCircleNotch.iconName]: faCircleNotch,
  [faExclamationTriangle.iconName]: faExclamationTriangle,
  [faBell.iconName]: faBell,
  [faBellOn.iconName]: faBellOn,
  [faVideo.iconName]: faVideo,
  [faPhoneSlash.iconName]: faPhoneSlash,
  [faVideoArrowUpRight.iconName]: faVideoArrowUpRight,
  [faXmark.iconName]: faXmark,
  [faGear.iconName]: faGear,
  [faAngleLeft.iconName]: faAngleLeft,
  [faLeft.iconName]: faLeft,
  [faRight.iconName]: faRight,
  [faUp.iconName]: faUp,
  [faDown.iconName]: faDown,
  [faAngleRight.iconName]: faAngleRight,
  [faAngleDown.iconName]: faAngleDown,
  [faAngleUp.iconName]: faAngleUp,
  [faCircleUser.iconName]: faCircleUser,
  [faBookmark.iconName]: faBookmark,
  [faBasketShopping.iconName]: faBasketShopping,
  [faCheck.iconName]: faCheck,
  [faArrowLeft.iconName]: faArrowLeft,
  [faArrowRight.iconName]: faArrowRight,
  [faArrowUp.iconName]: faArrowUp,
  [faArrowDown.iconName]: faArrowDown,
  [faImagePolaroid.iconName]: faImagePolaroid,
  [faCalendarDays.iconName]: faCalendarDays,
  [faSpinner.iconName]: faSpinner,
  [faBars.iconName]: faBars,
  [faCircleCheck.iconName]: faCircleCheck,
  [faGhost.iconName]: faGhost,
  [faTriangleExclamation.iconName]: faTriangleExclamation,
  [faUpRightAndDownLeftFromCenter.iconName]: faUpRightAndDownLeftFromCenter,
  [faMobileScreen.iconName]: faMobileScreen,
  [faDisplay.iconName]: faDisplay,
  [faMagnifyingGlass.iconName]: faMagnifyingGlass,
  [faMemo.iconName]: faMemo,
  [faMemoCircleCheck.iconName]: faMemoCircleCheck,
  [faCamera.iconName]: faCamera,
  [faEnvelope.iconName]: faEnvelope,
  [faEnvelopeOpenText.iconName]: faEnvelopeOpenText,
  [faLink.iconName]: faLink,
  [faClone.iconName]: faClone,
  [faEllipsis.iconName]: faEllipsis,
  [faCopy.iconName]: faCopy,
  [faFilePdf.iconName]: faFilePdf,
  [faFileExcel.iconName]: faFileExcel,
  [faTrashList.iconName]: faTrashList,
  [faShirt.iconName]: faShirt,
  [faGrid2Plus.iconName]: faGrid2Plus,
  [faChevronDown.iconName]: faChevronDown,
  [faChevronUp.iconName]: faChevronUp,
  [faChevronLeft.iconName]: faChevronLeft,
  [faChevronRight.iconName]: faChevronRight,
  [faChevronCircleDown.iconName]: faChevronCircleDown,
  [faChevronCircleUp.iconName]: faChevronCircleUp,
  [faCalendar.iconName]: faCalendar,
  [faCalendarWeek.iconName]: faCalendarWeek,
  [faCalendarDay.iconName]: faCalendarDay,
  [faCalendarRange.iconName]: faCalendarRange,
  [faObjectGroup.iconName]: faObjectGroup,
  [faPencil.iconName]: faPencil,
  [faTag.iconName]: faTag,
  [faMegaphone.iconName]: faMegaphone,
  [faFireFlameCurved.iconName]: faFireFlameCurved,
  [faLeftRight.iconName]: faLeftRight,
  [faArrowsUpDownLeftRight.iconName]: faArrowsUpDownLeftRight,
  [faUpDown.iconName]: faUpDown,
  [faArrowsMinimize.iconName]: faArrowsMinimize,
  [faArrowsToLine.iconName]: faArrowsToLine,
  [faPen.iconName]: faPen,
  [faTrash.iconName]: faTrash,
  [faTruck.iconName]: faTruck,
  [faBallPile.iconName]: faBallPile,
  [faArrowDownFromDottedLine.iconName]: faArrowDownFromDottedLine,
  [faLayerGroup.iconName]: faLayerGroup,
  [faRotateLeft.iconName]: faRotateLeft,
  [faAsterisk.iconName]: faAsterisk,
  [faEllipsisVertical.iconName]: faEllipsisVertical,
  [faLock.iconName]: faLock,
  [faCircleInfo.iconName]: faCircleInfo,
  [faThumbTack.iconName]: faThumbTack,
  [faIndustryWindows.iconName]: faIndustryWindows,
  [faInfinity.iconName]: faInfinity,
  [faFolderArrowDown.iconName]: faFolderArrowDown,
  [faFilePlus.iconName]: faFilePlus,
  [faCircleQuestion.iconName]: faCircleQuestion,
  [faTruckFast.iconName]: faTruckFast,
  [faCartShopping.iconName]: faCartShopping,
  [faStore.iconName]: faStore,
  [faCalendarPlus.iconName]: faCalendarPlus,
  [faShapes.iconName]: faShapes,
  [faFileArrowDown.iconName]: faFileArrowDown,
  [faCloudsMoon.iconName]: faCloudsMoon,
  [faCircleExclamation.iconName]: faCircleExclamation,
  [faFlag.iconName]: faFlag,
  [faShareNodes.iconName]: faShareNodes,
  [faEllipsisH.iconName]: faEllipsisH,
  [faDownload.iconName]: faDownload,
  [faVolume.iconName]: faVolume,
  [faVolumeSlash.iconName]: faVolumeSlash,
  [faClipboard.iconName]: faClipboard,
  [faUsers.iconName]: faUsers,
  [faEye.iconName]: faEye,
  [faEyeSlash.iconName]: faEyeSlash,
  [faClock.iconName]: faClock,
  [faCirclePlus.iconName]: faCirclePlus,
  [faCircleMinus.iconName]: faCircleMinus,
  [faCircleXmark.iconName]: faCircleXmark,
  [faFolderOpen.iconName]: faFolderOpen,
  [faFileInvoice.iconName]: faFileInvoice,
  [faLocationDot.iconName]: faLocationDot,
  [faPrint.iconName]: faPrint,
  [faMinus.iconName]: faMinus,
  [faPlus.iconName]: faPlus,
  [faTrashCan.iconName]: faTrashCan,
  [faSquareSlidersVertical.iconName]: faSquareSlidersVertical,
  [faFilter.iconName]: faFilter,
  [faBarsFilter.iconName]: faBarsFilter,
  [faPenNib.iconName]: faPenNib,
  [faUpload.iconName]: faUpload,
  [faRepeat.iconName]: faRepeat,
  [faCirclePlay.iconName]: faCirclePlay,
  [faBox.iconName]: faBox,
  [faSquare.iconName]: faSquare,
  [faGrid2.iconName]: faGrid2,
  [faStar.iconName]: faStar,
  [faMessage.iconName]: faMessage,
  [faMessageLines.iconName]: faMessageLines,
  [faAnglesRight.iconName]: faAnglesRight,
  [faTrophyStar.iconName]: faTrophyStar,
  [faList.iconName]: faList,
  [faListTree.iconName]: faListTree,
  [faCircleSmall.iconName]: faCircleSmall,
  [faMoneyCheckDollar.iconName]: faMoneyCheckDollar,
  [faSquareDollar.iconName]: faSquareDollar,
  [faLightbulbDollar.iconName]: faLightbulbDollar,
  [faMagnifyingGlassDollar.iconName]: faMagnifyingGlassDollar,
  [faMedal.iconName]: faMedal,
  [faChevronsUp.iconName]: faChevronsUp,
  [faClockRotateLeft.iconName]: faClockRotateLeft,
  [faHeart.iconName]: faHeart,
  [faUser.iconName]: faUser,
  [faPaperPlane.iconName]: faPaperPlane,
  [faExpand.iconName]: faExpand,
  [faExclamation.iconName]: faExclamation,
  [faWandMagicSparkles.iconName]: faWandMagicSparkles,
  [faChevronsRight.iconName]: faChevronsRight,
  [faAlignLeft.iconName]: faAlignLeft,
  [faAlignRight.iconName]: faAlignRight,
  [faAlignCenter.iconName]: faAlignCenter,
  [faAlignJustify.iconName]: faAlignJustify,
  [faA.iconName]: faA,
  [faFillDrip.iconName]: faFillDrip,
  [faBorderOuter.iconName]: faBorderOuter,
  [faBorderTopLeft.iconName]: faBorderTopLeft,
  [faIndent.iconName]: faIndent,
  [faText.iconName]: faText,
  [faSquarePlus.iconName]: faSquarePlus,
};
